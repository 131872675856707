import { onMounted, ref } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';

export async function useGenerateReCaptcha(action: string) {
  const token = ref();
  // initialize a instance
  const recaptchaInstance = useReCaptcha();

  onMounted(async () => {
    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded();
  });

  if (useRuntimeConfig().public.recaptchaSiteKey) {
    // get the token, a custom action could be added as argument to the method
    token.value = await recaptchaInstance?.executeRecaptcha(action);
  }

  return {
    token,
  };
}
